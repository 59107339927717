import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import {
  CREATE_ORGANIZATON_SAGA,
  GET_ORGANIZATON_DETAILS_SAGA,
  UPDATE_ORGANIZATON_SAGA
} from '../constants/AdminData'
import {
  showAdminLaoding,
  hideAdminLaoding,
  clearAdminData,
  createOrganiztion,
  createOrganiztionSaga,
  updateOrganiztion,
  updateOrganiztionSaga,
  getOrganiztionDetails,
  getOrganiztionDetailsSaga,
  eventHappened
} from '../actions/AdminData'
import { signOutSuccessAdmin } from 'redux/actions/Auth'
import AdminServices from 'services/AdminServices'
import AuthService from 'services/AuthService'
import { message } from 'antd'

export function * createOrganizationSagaGen () {
  yield takeEvery(CREATE_ORGANIZATON_SAGA, function * ({ payload }) {
    yield put(showAdminLaoding())
    try {
      const { data } = yield call(AdminServices.createOrganiztion, payload)
      yield put(createOrganiztion(data, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(createOrganiztionSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(createOrganiztion(e.response.data.message, true))
        } else {
          createOrganiztion('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(createOrganiztion('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideAdminLaoding())
  })
}

export function * getOrganiztionDetailsSagaGen () {
  yield takeEvery(GET_ORGANIZATON_DETAILS_SAGA, function * ({ payload }) {
    yield put(showAdminLaoding())
    try {
      const { data } = yield call(AdminServices.getOrganiztionDetails, payload)
      yield put(getOrganiztionDetails(data, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(getOrganiztionDetailsSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(getOrganiztionDetails(e.response.data.message, true))
        } else {
          getOrganiztionDetails('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getOrganiztionDetails('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideAdminLaoding())
  })
}

export function * updateOrganizationSagaGen () {
  yield takeEvery(UPDATE_ORGANIZATON_SAGA, function * ({ payload }) {
    yield put(showAdminLaoding())
    try {
      yield call(AdminServices.updateOrganiztion, payload)
      message.success('Organiztan info was updated Successfully!')

      // yield put(updateOrganiztion(false))
      yield put(eventHappened())
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(updateOrganiztionSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(updateOrganiztion(true))
          message.error(e.response.data.message)
        } else {
          updateOrganiztion(true)
        }
      } else {
        yield put(updateOrganiztion(true))
      }
    }

    yield put(hideAdminLaoding())
  })
}

export default function * rootSaga () {
  yield all([
    fork(createOrganizationSagaGen),
    fork(updateOrganizationSagaGen),
    fork(getOrganiztionDetailsSagaGen)
  ])
}
