import axios from 'axios'

const sspServices = {}

sspServices.getDashboard = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/portal-backend/v1/valify/dashboard-analytics/?lang=en`
      : '/portal-backend/v1/valify/dashboard-analytics/?lang=en',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

sspServices.getUserSessions = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/portal-backend/v1/valify/bundle-sessions/?lang=en`
      : '/portal-backend/v1/valify/bundle-sessions/?lang=en',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

sspServices.getSessionDetails = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/portal-backend/v1/valify/bundle-session-details/?lang=en`
      : '/portal-backend/v1/valify/bundle-session-details/?lang=en',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

sspServices.getUserTransactions = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/portal-backend/v1/valify/bundle-transactions/`
      : '/portal-backend/v1/valify/bundle-transactions/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

sspServices.changePassword = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/portal-backend/v1/accounts/change-password/?lang=en`
      : '/portal-backend/v1/accounts/change-password/?lang=en',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

sspServices.getProfileData = function (data) {
  const config = {
    method: 'get',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/portal-backend/v1/accounts/profile/?lang=en`
      : '/portal-backend/v1/accounts/profile/?lang=en',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

sspServices.getBundleServices = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/portal-backend/v1/valify/bundle-services/`
      : '/portal-backend/v1/valify/bundle-services/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

sspServices.getBundleAnalytics = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/portal-backend/v1/valify/bundle-analytics/?lang=en`
      : '/portal-backend/v1/valify/bundle-analytics/?lang=en',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }
  return axios(config)
}

export default sspServices
