import { all } from 'redux-saga/effects'
import Auth from './Auth'
import AdminSessions from './AdminSessions'
import AdminOrganiztions from './AdminOrganiztions'
import AdminBundles from './AdminBundles'
import AdminTransactions from './AdminTransactions'
import ssp from './SSP'

export default function* rootSaga() {
  yield all([
    Auth(),
    AdminSessions(),
    AdminOrganiztions(),
    AdminBundles(),
    AdminTransactions(),
    ssp(),
  ])
}
