export const SIGNIN = 'SIGNIN'
export const SIGNIN_ADMIN = 'SIGNIN_ADMIN'
export const AUTHENTICATED = 'AUTHENTICATED'
export const AUTHENTICATED_ADMIN = 'AUTHENTICATED_ADMIN'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const SIGNOUT = 'SIGNOUT'
export const SIGNOUT_ADMIN = 'SIGNOUT_ADMIN'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNOUT_SUCCESS_ADMIN = 'SIGNOUT_SUCCESS_ADMIN'
export const SIGNUP = 'SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'
export const AUTH_TOKEN = 'auth_token'
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE'
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = 'SIGNIN_WITH_GOOGLE_AUTHENTICATED'
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK'
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = 'SIGNIN_WITH_FACEBOOK_AUTHENTICATED'
export const UNINITIALIZED_AUTHENTICATION = 'UNINITIALIZED_AUTHENTICATION'
