import { HIDEERRORMESSAGE, SHOWERRORMESSAGE } from 'redux/constants/Data'

const initState = {
  showErrorMessage: false
}

const auth = (state = initState, action) => {
  switch (action.type) {
    case SHOWERRORMESSAGE:
      return {
        ...state,
        showErrorMessage: true
      }
    case HIDEERRORMESSAGE:
      return {
        ...state,
        showErrorMessage: false
      }

    default:
      return state
  }
}

export default auth
