import React from 'react'
import { Menu, Dropdown, Avatar } from 'antd'
import { connect } from 'react-redux'
import { EditOutlined, LogoutOutlined } from '@ant-design/icons'
import Icon from 'components/util-components/Icon'
import { signOutAdmin } from 'redux/actions/Auth'
import { ADMIN_PREFIX_PATH } from 'configs/AppConfig'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { onSwitchTheme } from 'redux/actions'

const menuItem = [
  {
    title: 'Change Password',
    icon: EditOutlined,
    path: `${ADMIN_PREFIX_PATH}/change-password`
  }
]

export const AdminNavProfile = ({ signOutAdmin, onSwitchTheme }) => {
  const { switcher, themes } = useThemeSwitcher()

  const toggleTheme = () => {
    onSwitchTheme('light')
    switcher({ theme: themes.light })
  }

  const profileImg = '/img/avatars/user-286.svg'
  const profileMenu = (
    <div className='nav-profile nav-dropdown'>
      <div className='nav-profile-header'>
        <div className='d-flex'>
          <Avatar src={profileImg} />
          <div className='pl-3'>
            <h4 className='mt-2'>{localStorage.getItem('USERNAME')}</h4>
          </div>
        </div>
      </div>
      <div className='nav-profile-body'>
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className='mr-3' type={el.icon} />
                  <span className='font-weight-normal'>{el.title}</span>
                </a>
              </Menu.Item>
            )
          })}
          <Menu.Item
            key={menuItem.length + 1}
            onClick={() => {
              signOutAdmin()
              toggleTheme('light')
            }}
          >
            <span>
              <LogoutOutlined className='mr-3' />
              <span className='font-weight-normal'>Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  )
  return (
    <Dropdown placement='bottomRight' overlay={profileMenu} trigger={['click']}>
      <Menu className='d-flex align-item-center' mode='horizontal'>
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}

const mapStateToProps = ({ theme }) => {
  const { currentTheme } = theme
  return {
    currentTheme
  }
}

const mapDispatchToProps = {
  onSwitchTheme,
  signOutAdmin
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavProfile)
