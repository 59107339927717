import axios from 'axios'
import { hash } from '../utils/functions.js'

const AuthService = {}

AuthService.login = function (data) {
  const raw_password = data.password
  data.password = hash(raw_password)
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/portal-backend/v2/authentication/login/?lang=en`
      : '/portal-backend/v2/authentication/login/?lang=en',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config).catch((error) =>{ 
  if(error.response){
    if(error.response.data["code"] !== undefined){
      if(error.response.data["code"] === 7001){
        config.data.password = raw_password
        config.data.is_hashed = 0
        return axios(config)
      }
    }
   }
   throw error
})
}

AuthService.loginAdmin = function (data) {
  const raw_password = data.password
  data.password = hash(raw_password)
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1.1/authentication/login/`
      : '/admin-portal-backend/v1.1/authentication/login/ ',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config).catch((error) =>{ 
    if(error.response){
      if(error.response.data["code"] !== undefined){
        if(error.response.data["code"] === 7001){
          config.data.password = raw_password
          config.data.is_hashed = 0
          return axios(config)
        }
      }
     }
     throw error
  })
}

AuthService.adminRefreshToken = function () {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/authentication/refresh/`
      : '/admin-portal-backend/v1/authentication/refresh/ ',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  }

  return axios(config)
}

AuthService.sspRefreshToken = function () {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/portal-backend/v1/authentication/refresh/?lang=en`
      : '/portal-backend/v1/authentication/refresh/?lang=en',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  }

  return axios(config)
}

AuthService.logout = function () {
  const config = {
    method: 'get',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/portal-backend/v1/authentication/logout/`
      : '/portal-backend/v1/authentication/logout/',
    withCredentials: true,
  }

  return axios(config)
}

AuthService.logoutAdmin = function () {
  const config = {
    method: 'get',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/authentication/logout/`
      : '/admin-portal-backend/v1/authentication/logout/',
    withCredentials: true,
  }

  return axios(config)
}

export default AuthService
