import { combineReducers } from 'redux'
import Auth from './Auth'
import Theme from './Theme'
import Data from './Data'
import AdminData from './AdminData'
import ssp from './SSPData'

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  data: Data,
  admin: AdminData,
  ssp: ssp,
})

export default reducers
