import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { ADMIN_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${ADMIN_PREFIX_PATH}/dashboard`}
          component={lazy(() => import('./dashboard'))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/analytic`}
          component={lazy(() => import('./analytic'))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/sanction`}
          component={lazy(() => import('./sanction'))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/name-match`}
          component={lazy(() => import('./nameMatch'))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/generic-ocr`}
          component={lazy(() => import('./generic-ocr'))}
        />
        <Route
          path={`${ADMIN_PREFIX_PATH}/bundle-analytics/:clientId/:bundleId`}
          component={lazy(() => import('./analytic/BundleAnalytics'))}
        />
        <Route
          exact
          path={`${ADMIN_PREFIX_PATH}/sessions`}
          component={lazy(() => import('./sessions'))}
        />
        <Route
          exact
          path={`${ADMIN_PREFIX_PATH}/transactions`}
          component={lazy(() => import('./transactions'))}
        />
        <Route
          exact
          path={`${ADMIN_PREFIX_PATH}/export-data`}
          component={lazy(() => import('./exportData'))}
        />
        <Route
          exact
          path={`${ADMIN_PREFIX_PATH}/export-transactions`}
          component={lazy(() => import('./exportTransactions'))}
        />
        <Route
          exact
          path={`${ADMIN_PREFIX_PATH}/bundle-sessions/:bundleId`}
          component={lazy(() => import('./sessions/BundleSessions.js'))}
        />
        <Route
          exact
          path={`${ADMIN_PREFIX_PATH}/sessions/:bundleId/:sessionId`}
          component={lazy(() => import('./sessions/SessionPage'))}
        />
        <Route
          exact
          path={`${ADMIN_PREFIX_PATH}/clients`}
          component={lazy(() => import('./clients'))}
        />
        <Route
          exact
          path={`${ADMIN_PREFIX_PATH}/clients/:clientId`}
          component={lazy(() => import('./clients/clientPage'))}
        />
        <Route
          exact
          path={`${ADMIN_PREFIX_PATH}/change-password`}
          component={lazy(() => import('./change-password/index'))}
        />
        <Route
          exact
          path={`${ADMIN_PREFIX_PATH}/clients/:clientId/:bundleId`}
          component={lazy(() => import('./clients/bundlePage/index'))}
        />
        <Redirect from={`${ADMIN_PREFIX_PATH}`} to={`${ADMIN_PREFIX_PATH}/dashboard`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews)
