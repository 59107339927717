export const SHOW_ADMIN_LOADING = 'SHOW_ADMIN_LOADING'
export const HIDE_ADMIN_LOADING = 'HIDE_ADMIN_LOADING'
export const GET_DASHBOARD_ANALYTICS_SAGA = 'GET_DASHBOARD_ANALYTICS_SAGA'
export const GET_DASHBOARD_ANALYTICS = 'GET_DASHBOARD_ANALYTICS'
export const GET_ORGANIZATON_DETAILS_SAGA = 'GET_ORGANIZATON_DETAILS_SAGA'
export const GET_ORGANIZATON_DETAILS = 'GET_ORGANIZATON_DETAILS'
export const CREATE_ORGANIZATON_SAGA = 'CREATE_ORGANIZATON_SAGA'
export const CREATE_ORGANIZATON = 'CREATE_ORGANIZATON'
export const UPDATE_ORGANIZATON_SAGA = 'UPDATE_ORGANIZATON_SAGA'
export const UPDATE_ORGANIZATON = 'UPDATE_ORGANIZATON'
export const GET_ORGANIZTIONS_SAGA = 'GET_ORGANIZTIONS_SAGA'
export const GET_ORGANIZTIONS = 'GET_ORGANIZTIONS'
export const EXPORT_DATA_SAGA = 'EXPORT_DATA_SAGA'
export const EXPORT_DATA = 'EXPORT_DATA'
export const GET_VALIFY_SERVICES_SAGA = 'GET_VALIFY_SERVICES_SAGA'
export const GET_VALIFY_SERVICES = 'GET_VALIFY_SERVICES'
export const GET_ORGANIZTION_BUNLDES_SAGA = 'GET_ORGANIZTION_BUNLDES_SAGA'
export const GET_ORGANIZTION_BUNLDES = 'GET_ORGANIZTION_BUNLDES'
export const CREATE_BUNDLE_SAGA = 'CREATE_BUNDLE_SAGA'
export const CREATE_BUNDLE = 'CREATE_BUNDLE'
export const GET_BUNLDE_DETAILS_SAGA = 'GET_BUNLDE_DETAILS_SAGA'
export const GET_BUNLDE_DETAILS = 'GET_BUNLDE_DETAILS'
export const GET_BUNDLE_ANALYTICS_SAGA = 'GET_BUNDLE_ANALYTICS_SAGA'
export const GET_BUNDLE_ANALYTICS = 'GET_BUNDLE_ANALYTICS'
export const GET_BUNDLE_SESSIONS_SAGA = 'GET_BUNDLE_SESSIONS_SAGA'
export const GET_BUNDLE_SESSIONS = 'GET_BUNDLE_SESSIONS'
export const GET_SESSION_DETAILS_SAGA = 'GET_SESSION_DETAILS_SAGA'
export const GET_SESSION_DETAILS = 'GET_SESSION_DETAILS'
export const GET_BUNDLE_TRANSACTIONS_SAGA = 'GET_BUNDLE_TRANSACTIONS_SAGA'
export const GET_BUNDLE_TRANSACTIONS = 'GET_BUNDLE_TRANSACTIONS'
export const GET_TRANSACTION_DETAILS_SAGA = 'GET_TRANSACTION_DETAILS_SAGA'
export const GET_TRANSACTION_DETAILS = 'GET_TRANSACTION_DETAILS'
export const GET_BUNDLE_SERVICES_SAGA = 'GET_BUNDLE_SERVICES_SAGA'
export const GET_BUNDLE_SERVICES = 'GET_BUNDLE_SERVICES'
export const GET_FULL_IMAGE_SAGA = 'GET_FULL_IMAGE_SAGA'
export const GET_FULL_IMAGE = 'GET_FULL_IMAGE'
export const SHOW_SESSIONS_LOADING = 'SHOW_SESSIONS_LOADING'
export const HIDE_SESSIONS_LOADING = 'HIDE_SESSIONS_LOADING'
export const SHOW_SERVICES_LOADING = 'SHOW_SERVICES_LOADING'
export const HIDE_SERVICES_LOADING = 'HIDE_SERVICES_LOADING'
export const SHOW_TRANSACTION_LOADING = 'SHOW_TRANSACTION_LOADING'
export const HIDE_TRANSACTION_LOADING = 'HIDE_TRANSACTION_LOADING'
export const SHOW_FULL_IMAGE__LOADING = 'SHOW_FULL_IMAGE__LOADING'
export const HIDE_FULL_IMAGE__LOADING = 'HIDE_FULL_IMAGE__LOADING'
export const CLEAR_ADMIN_DATA = 'CLEAR_ADMIN_DATA'
export const EVENT_SUCCEDED = 'EVENT_SUCCEDED'
export const GET_BOXED_IMAGE = 'GET_BOXED_IMAGE'
export const GET_BOXED_IMAGE_SAGA = 'GET_BOXED_IMAGE_SAGA'
export const SHOW_BOXED_IMAGE_LOADING = 'SHOW_BOXED_IMAGE_LOADING'
export const HIDE_BOXED_IMAGE_LOADING = 'HIDE_BOXED_IMAGE_LOADING'
export const EXPORT_TRANSACTIONS_SAGA = 'EXPORT_TRANSACTIONS_SAGA'
export const EXPORT_TRANSACTIONS = 'EXPORT_TRANSACTIONS'
