import {
  GET_DASHBOARD_SAGA,
  GET_DASHBOARD,
  CLEAR_SSP_DATA,
  GET_PROFILE_DATA_SAGA,
  GET_PROFILE,
  GET_USER_SESSIONS_SAGA,
  GET_USER_SESSIONS,
  GET_USER_TRANSACTIONS_SAGA,
  GET_USER_TRANSACTIONS,
  GET_BUNDLE_SERVICES,
  GET_SSP_BUNDLE_SERVICES_SAGA,
  GET_SSP_BUNDLE_ANALYTICS_SAGA,
  GET_SSP_BUNDLE_ANALYTICS,
  GET_SSP_SESSION_DETAILS_SAGA,
  GET_SSP_SESSION_DETAILS,
  CHANGE_SSP_PASSWORD_SAGA,
  CHANGE_SSP_PASSWORD,
  RESET_SSP_CHANGED_PASSWORD,
} from 'redux/constants/SSP'

export function resetSspChangedPassword() {
  return {
    type: RESET_SSP_CHANGED_PASSWORD,
  }
}

export function getDashboardSaga(data) {
  return {
    type: GET_DASHBOARD_SAGA,
    payload: data,
  }
}

export function getDashboard(payload, isFailed) {
  return {
    type: GET_DASHBOARD,
    payload,
    isFailed,
  }
}

export function getBundleServicesSaga(data) {
  return {
    type: GET_SSP_BUNDLE_SERVICES_SAGA,
    payload: data,
  }
}

export function getBundleServices(payload, isFailed) {
  return {
    type: GET_BUNDLE_SERVICES,
    payload,
    isFailed,
  }
}

export function getSspBundleAnalyticsSaga(data) {
  return {
    type: GET_SSP_BUNDLE_ANALYTICS_SAGA,
    payload: data,
  }
}

export function getSspBundleAnalytics(payload, isFailed) {
  return {
    type: GET_SSP_BUNDLE_ANALYTICS,
    payload,
    isFailed,
  }
}

export function getSspSessionDetialsSaga(data) {
  return {
    type: GET_SSP_SESSION_DETAILS_SAGA,
    payload: data,
  }
}

export function getSspSessionDetials(payload, isFailed) {
  return {
    type: GET_SSP_SESSION_DETAILS,
    payload,
    isFailed,
  }
}

export function getUserSessionsSaga(data) {
  return {
    type: GET_USER_SESSIONS_SAGA,
    payload: data,
  }
}

export function getUserSessions(payload, isFailed, totalSessions) {
  return {
    type: GET_USER_SESSIONS,
    payload,
    isFailed,
    totalSessions,
  }
}

export function getUserTransactionsSaga(data) {
  return {
    type: GET_USER_TRANSACTIONS_SAGA,
    payload: data,
  }
}

export function getUserTransactions(payload, isFailed, totalTransactions) {
  return {
    type: GET_USER_TRANSACTIONS,
    payload,
    isFailed,
    totalTransactions,
  }
}

export function changeSspPasswordSaga(data) {
  return {
    type: CHANGE_SSP_PASSWORD_SAGA,
    payload: data,
  }
}

export function changeSspPassword(payload, isFailed) {
  return {
    type: CHANGE_SSP_PASSWORD,
    payload,
    isFailed,
  }
}

export function getProfileDataSaga(data) {
  return {
    type: GET_PROFILE_DATA_SAGA,
    payload: data,
  }
}

export function getProfileData(payload, isFailed) {
  return {
    type: GET_PROFILE,
    payload,
    isFailed,
  }
}

export function clearSSPData(payload, isFailed) {
  return {
    type: CLEAR_SSP_DATA,
    payload,
    isFailed,
  }
}
