import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import { SIGNIN, SIGNOUT, SIGNIN_ADMIN, SIGNOUT_ADMIN } from '../constants/Auth'
import {
  showAuthMessage,
  uninitialized_authentication,
  authenticated,
  authenticatedAdmin,
  signOutSuccess,
  signOutSuccessAdmin,
} from '../actions/Auth'
import { clearAdminData } from 'redux/actions/AdminData'

import AuthService from 'services/AuthService'
import { message } from 'antd'

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload
    try {
      const { data } = yield call(AuthService.login, { email, password })
      // localStorage.setItem("AUTH_TOKEN", data.access_token);
      // localStorage.setItem("REFRESH_TOKEN", data.refresh_token);
      localStorage.setItem('isInitialized', data.is_initialized)
      localStorage.setItem('USERNAME', data.name)
      localStorage.setItem('ACCOUNT_TYPE', data.account_type)
      if (data.is_initialized){
        localStorage.setItem('BUNDLES', JSON.stringify(data.bundles))
        yield put(authenticated(data.access_token, data.account_type))
      } else {
        yield put(uninitialized_authentication(data.access_token))
      }
    } catch (e) {
      if (e.response) {
        if (e.response.data) {
          yield put(showAuthMessage(e.response.data.message))
        } else {
          yield put(showAuthMessage('Sorry ! Something went wrong . Please try again later !'))
        }
      } else {
        yield put(showAuthMessage('Please make sure of your internet connection!'))
      }
    }
  })
}

export function* signInWithAdminEmail() {
  yield takeEvery(SIGNIN_ADMIN, function* ({ payload }) {
    const { email, password } = payload
    try {
      const { data } = yield call(AuthService.loginAdmin, { email, password })
      localStorage.setItem('USERNAME', data.name)
      localStorage.setItem('isAdmin', true)
      localStorage.setItem('permissions', JSON.stringify(data.permissions_list))
      localStorage.setItem('isInitialized', 'true')
      yield put(authenticatedAdmin(data.access_token))
    } catch (e) {
      if (e.response) {
        if (e.response.data) {
          yield put(showAuthMessage(e.response.data.message))
        } else {
          yield put(showAuthMessage('Sorry ! Something went wrong . Please try again later !'))
        }
      } else {
        yield put(showAuthMessage('Please make sure of your internet connection!'))
      }
    }
  })
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      yield call(AuthService.logout)
      localStorage.removeItem('USERNAME')
      localStorage.removeItem('isInitialized')
      localStorage.removeItem('BUNDLES')
      localStorage.removeItem('showingSessions')
      localStorage.removeItem('ACCOUNT_TYPE')
      yield put(signOutSuccess())
    } catch (e) {
      if (e.response) {
        if (e.response.data) {
          message.error(e.response.data.message)
        } else {
          message.error('somthing went wrong!')
        }
      } else {
        message.error('Please make sure of your internet connection !')
      }
    }
  })
}

export function* signOutAdmin() {
  yield takeEvery(SIGNOUT_ADMIN, function* () {
    try {
      yield call(AuthService.logoutAdmin)
      localStorage.removeItem('USERNAME')
      localStorage.removeItem('isAdmin')
      localStorage.removeItem('isInitialized')
      localStorage.removeItem('permissions')
      yield put(signOutSuccessAdmin())
      yield put(clearAdminData())
    } catch (e) {
      if (e.response) {
        if (e.response.data) {
          message.error(e.response.data.message)
        } else {
          message.error('somthing went wrong!')
        }
      } else {
        message.error('Please make sure of your internet connection !')
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(signInWithFBEmail),
    fork(signOut),
    fork(signInWithAdminEmail),
    fork(signOutAdmin),
  ])
}
