export const GET_DASHBOARD_SAGA = 'GET_DASHBOARD_SAGA'
export const GET_DASHBOARD = 'GET_DASHBOARD'
export const GET_SSP_BUNDLE_ANALYTICS_SAGA = 'GET_SSP_BUNDLE_ANALYTICS_SAGA'
export const GET_SSP_BUNDLE_ANALYTICS = 'GET_SSP_BUNDLE_ANALYTICS'
export const GET_SSP_SESSION_DETAILS_SAGA = 'GET_SSP_SESSION_DETAILS_SAGA'
export const GET_SSP_SESSION_DETAILS = 'GET_SSP_SESSION_DETAILS'
export const GET_PROFILE_DATA_SAGA = 'GET_PROFILE_DATA_SAGA'
export const CHANGE_SSP_PASSWORD_SAGA = 'CHANGE_SSP_PASSWORD_SAGA'
export const CHANGE_SSP_PASSWORD = 'CHANGE_SSP_PASSWORD'
export const RESET_SSP_CHANGED_PASSWORD = 'RESET_SSP_CHANGED_PASSWORD'
export const GET_PROFILE = 'GET_PROFILE'
export const GET_SSP_BUNDLE_SERVICES_SAGA = 'GET_SSP_BUNDLE_SERVICES_SAGA'
export const GET_BUNDLE_SERVICES = 'GET_BUNDLE_SERVICES'
export const GET_USER_SESSIONS_SAGA = 'GET_USER_SESSIONS_SAGA'
export const GET_USER_SESSIONS = 'GET_USER_SESSIONS'
export const GET_USER_TRANSACTIONS_SAGA = 'GET_USER_TRANSACTIONS_SAGA'
export const GET_USER_TRANSACTIONS = 'GET_USER_TRANSACTIONS'
export const CLEAR_SSP_DATA = 'CLEAR_SSP_DATA'
