import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Grid } from 'antd'
import IntlMessage from '../util-components/IntlMessage'
import Icon from '../util-components/Icon'
import navigationConfig from 'configs/NavigationConfig'
import { connect } from 'react-redux'
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant'
import utils from 'utils'
import { onMobileNavToggle } from 'redux/actions/Theme'
import { GoldOutlined } from '@ant-design/icons'
import axios from 'axios'
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const { SubMenu } = Menu
const { useBreakpoint } = Grid

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString()

const setDefaultOpen = (key) => {
  const keyList = []
  let keyString = ''
  if (key) {
    const arr = key.split('-')
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index]
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`)
      keyList.push(keyString)
    }
  }
  return keyList
}

const SideNavContent = (props) => {
  const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle } = props
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false)
    }
  }

  const menuClone = props.navigationMenuState.reduce((total_list, item) => {
    return [
      ...total_list, 
      {
        ...item,
        submenu: [...item.submenu],
      }
    ]
  },[])

  if (!props.services?.includes('sanction_shield')) {
    menuClone[0].submenu = menuClone[0].submenu.filter(
      (item) => item.title !== 'sidenav.mainMenu.sanction-lists',
    )
  }



  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? 'hide-group-title' : ''}
    >
      {/* {console.log("navigationMenuState", props.navigationMenuState)} */}
      {menuClone.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup key={menu.key} title={setLocale(localization, menu.title)}>
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? <Icon type={subMenuSecond?.icon} /> : null}
                      <span>{setLocale(localization, subMenuSecond.title)}</span>
                      <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                </Menu.Item>
              ),
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </Menu.Item>
        ),
      )}
    </Menu>
  )
}

const TopNavContent = (props) => {
  const { topNavColor, localization } = props
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {/* {console.log("navigationMenuState", props.navigationMenuState)} */}

      {props.navigationMenuState.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>{setLocale(localization, subMenuSecond.title)}</span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              ),
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        ),
      )}
    </Menu>
  )
}

function useForceUpdate() {
  const [, setValue] = useState(0) // integer state
  return () => setValue((value) => value + 1) // update the state to force render
}

const MenuContent = (props) => {
  const menuClone = navigationConfig.reduce((total_list, item) => {
    return [
      ...total_list, 
      {
        ...item,
        submenu: [...item.submenu],
        // subMenu: [...item.subMenu || []],
      }
    ]
  },[])
  const [navigationMenuState, setNavigationMenuState] = useState(menuClone)

  const [bundles, setBundles] = useState([])
  const [services, setServices] = useState(null)

  const forceUpdate = useForceUpdate()

  const getBundles = async () => {
    try {
      const bundlesRes = await axios.get(
        process.env.REACT_APP_DOMAIN_NAME
          ? `${process.env.REACT_APP_DOMAIN_NAME}/portal-backend/v1/valify/organization-bundles/?lang=en`
          : '/portal-backend/v1/valify/organization-bundles/?lang=en',
        { withCredentials: true },
      )
      setBundles(bundlesRes.data.bundles)
    } catch (e) {
      // console.log(e.response);
      if (e.response) {
        if (e.response.status === 403) {
          // setBundles(JSON.parse(localStorage.getItem('BUNDLES')))
        }
      }
    }
  }

  const getServices = async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_DOMAIN_NAME
          ? `${process.env.REACT_APP_DOMAIN_NAME}/portal-backend/v1/valify/client-services/?lang=en`
          : '/portal-backend/v1/valify/client-services/?lang=en',
        { withCredentials: true },
      )
      setServices(res.data.services)
    } catch (e) {
      console.log(e);
    }
  }

  const showMenu = (arr) => {
    const newArr = menuClone
    newArr[0].submenu[2].submenu = arr.length
      ? arr.map((bundle) => {
          return {
            key: `l - ${bundle.id}`,
            path: `${APP_PREFIX_PATH}/pages/bundle/${bundle.id}/sessions`,
            title: bundle.name,
            icon: GoldOutlined,
            breadcrumb: false,
            submenu: [],
          }
        })
      : []
    setNavigationMenuState(newArr)
    forceUpdate()
  }
  
  useEffect(() => {
    getBundles()
    getServices()
  }, [])

  useEffect(() => {
    showMenu(bundles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundles])

  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} services={services} navigationMenuState={navigationMenuState} />
  ) : (
    <TopNavContent {...props} services={services} navigationMenuState={navigationMenuState} />
  )
}

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme
  return { sideNavTheme, topNavColor }
}

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent)
