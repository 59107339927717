import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Grid } from 'antd'
import IntlMessage from '../util-components/IntlMessage'
import Icon from '../util-components/Icon'
import AdminnavigationConfig from 'configs/AdminNavigationConfig'
import { connect } from 'react-redux'
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant'
import utils from 'utils'
import { onMobileNavToggle } from 'redux/actions/Theme'
import {
  DashboardOutlined,
  LineChartOutlined,
  DollarOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  ScanOutlined,
} from '@ant-design/icons'
import { ADMIN_PREFIX_PATH } from 'configs/AppConfig'

const { SubMenu } = Menu
const { useBreakpoint } = Grid

function useForceUpdate() {
  const [, setValue] = useState(0) // integer state
  return () => setValue((value) => value + 1) // update the state to force render
}

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString()

const setDefaultOpen = (key) => {
  const keyList = []
  let keyString = ''
  if (key) {
    const arr = key.split('-')
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index]
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`)
      keyList.push(keyString)
    }
  }
  return keyList
}

const SideNavContent = (props) => {
  const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle } = props
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false)
    }
  }

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? 'hide-group-title' : ''}
    >
      {/* {console.log('navigationMenuState', routeInfo)} */}
      {props.navigationMenuState.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup key={menu.key} title={setLocale(localization, menu.title)}>
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      {subMenuSecond.icon ? <Icon type={subMenuSecond?.icon} /> : null}
                      <span>{setLocale(localization, subMenuSecond.title)}</span>
                      <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                </Menu.Item>
              ),
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
          </Menu.Item>
        ),
      )}
    </Menu>
  )
}

const TopNavContent = (props) => {
  const { topNavColor, localization } = props
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {/* {console.log("navigationMenuState", props.navigationMenuState)} */}

      {props.navigationMenuState.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>{setLocale(localization, subMenuSecond.title)}</span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              ),
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        ),
      )}
    </Menu>
  )
}

const MenuContent = (props) => {
  const [navigationMenuState, setNavigationMenuState] = useState(AdminnavigationConfig)
  const forceUpdate = useForceUpdate()
  useEffect(() => {
    const filterMenu = () => {
      const newArr = [
        {
          key: 'admin',
          path: `${ADMIN_PREFIX_PATH}`,
          title: 'sidenav.mainMenu',
          icon: DashboardOutlined,
          breadcrumb: false,
          submenu: [
            {
              key: 'admin-dashboard',
              path: `${ADMIN_PREFIX_PATH}/dashboard`,
              title: 'adminSideNav.dashboard',
              icon: DashboardOutlined,
              breadcrumb: false,
              submenu: [],
            },
            {
              key: 'admin-analytics',
              path: `${ADMIN_PREFIX_PATH}/analytic`,
              title: 'adminSideNav.analytic',
              icon: DollarOutlined,
              breadcrumb: false,
              submenu: [],
            },
            {
              key: 'admin-sessions',
              path: `${ADMIN_PREFIX_PATH}/sessions`,
              title: 'adminSideNav.sessions',
              icon: LineChartOutlined,
              breadcrumb: false,
              submenu: [],
            },
            {
              key: 'admin-clients',
              path: `${ADMIN_PREFIX_PATH}/clients`,
              title: 'adminSideNav.clients',
              icon: UserOutlined,
              breadcrumb: false,
              submenu: [],
            },
            {
              key: 'admin-transactions',
              path: `${ADMIN_PREFIX_PATH}/transactions`,
              title: 'adminSideNav.transactions',
              icon: LineChartOutlined,
              breadcrumb: false,
              submenu: [],
            },
            {
              key: 'admin-export-data',
              path: `${ADMIN_PREFIX_PATH}/export-data`,
              title: 'adminSideNav.export-data',
              icon: LineChartOutlined,
              breadcrumb: false,
              submenu: [],
            },
            {
              key: 'admin-export-transactions',
              path: `${ADMIN_PREFIX_PATH}/export-transactions`,
              title: 'adminSideNav.export-transactions',
              icon: LineChartOutlined,
              breadcrumb: false,
              submenu: [],
            },
            {
              key: 'admin-sanction-shield',
              path: `${ADMIN_PREFIX_PATH}/sanction`,
              title: 'adminSideNav.sanction',
              icon: ExclamationCircleOutlined,
              breadcrumb: false,
              submenu: [],
            },
            {
              key: 'admin-name-match-compare',
              path: `${ADMIN_PREFIX_PATH}/name-match`,
              title: 'adminSideNav.name-match',
              icon: ExclamationCircleOutlined,
              breadcrumb: false,
              submenu: [],
            },
            {
              key: 'admin-generic-ocr',
              path: `${ADMIN_PREFIX_PATH}/generic-ocr`,
              title: 'adminSideNav.generic_ocr',
              icon: ScanOutlined,
              breadcrumb: false,
              submenu: [],
            },
          ],
        },
      ]
      if (!JSON.parse(localStorage.getItem('permissions'))?.includes('admin_analytics')) {
        newArr[0].submenu = newArr[0].submenu.filter(
          (item) => item.title !== 'adminSideNav.dashboard',
        )
      }

      if (!JSON.parse(localStorage.getItem('permissions'))?.includes('transaction_monitoring')) {
        newArr[0].submenu = newArr[0].submenu.filter(
          (item) => item.title !== 'adminSideNav.sessions',
        )
      }

      if (!JSON.parse(localStorage.getItem('permissions'))?.includes('bundle_analytics')) {
        newArr[0].submenu = newArr[0].submenu.filter(
          (item) => item.title !== 'adminSideNav.analytic',
        )
      }

      if (!JSON.parse(localStorage.getItem('permissions'))?.includes('organization_management')) {
        newArr[0].submenu = newArr[0].submenu.filter(
          (item) => item.title !== 'adminSideNav.clients',
        )
      }

      if (!JSON.parse(localStorage.getItem('permissions'))?.includes('service_demo')) {
        newArr[0].submenu = newArr[0].submenu.filter(
          (item) => item.title !== 'adminSideNav.generic_ocr',
        )
      }

      setNavigationMenuState(newArr)

      forceUpdate()
    }

    filterMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} navigationMenuState={navigationMenuState} />
  ) : (
    <TopNavContent {...props} navigationMenuState={navigationMenuState} />
  )
}

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme
  return { sideNavTheme, topNavColor }
}

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent)
