import CryptoJS from 'crypto-js';


export function downloadBlob(blob, name = 'file.txt') {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob)

  // Create a link element
  const link = document.createElement('a')

  // Set link's href to point to the Blob URL
  link.href = blobUrl
  link.download = name

  // Append link to the body
  document.body.appendChild(link)

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  )

  // Remove link from body
  document.body.removeChild(link)
}

// this function is used to convert base64 images to blob to prepare it to be downloaded
// it is usually used in downloading session & transactions images
export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const hash = (string) => {
  return CryptoJS.SHA256(string + "#valify").toString();
}

// it's a function to convert the unixtimestamp to a readable date format
//it's usually used because the date always come from backend side in unixtimestamp format
export const convertUnixTimeStamp = (unixTimestamp) => {
  const dateObject = new Date(unixTimestamp * 1000)
  const datearray = dateObject.toLocaleString().split('/')
  return datearray[1] + '/' + datearray[0] + '/' + datearray[2]
}

//it's a function to caluclate session duration in minutes
//used to display session duration in session details page
export const showTimeInMinutes = (time) => {
  const minutes = Math.floor(time / 60)
  const seconds = time - minutes * 60

  return minutes !== 0 ? `${minutes} Minutes ${seconds} Seconds` : `${seconds} Seconds`
}

export const calculateProgress = (oldValue, newValue) => {
  return ((newValue - oldValue) / oldValue).toFixed(4)
}

// export const hasDuplicates = (array) => {
//   return new Set(array).size !== array.length
// }

//when creating a new bundle we set its tiers & we have to validate in front-end side if the user didn't
// entered tiers that are overlapping
export const checkOverlappingTiers = (array) => {
  array.forEach((tier, index, arr) => {
    if (arr.length - index !== 1) {
      if (
        tier.minimum_interval >= arr[index + 1].maximum_interval ||
        arr[index + 1].minimum_interval <= tier.maximum_interval
      ) {
        // overlapping Ranges
        return true
      }
    }
  })
}

// also it's used when creating a new bundle for making sure that the user entered valid ranges
export const checkValidRanges = (array) => {
  let valid = true
  array.forEach((tier, index, arr) => {
    if (tier.maximum_interval <= tier.minimum_interval) {
      valid = false
    }
  })
  return valid
}

// also when creating a new bundle we have to make sure that there are no gaps between the tiers created.
export const checkGaps = (array) => {
  let gapsExist = false
  array.forEach((tier, index, arr) => {
    if (arr.length - index !== 1) {
      if (arr[index + 1].minimum_interval - tier.maximum_interval > 1) {
        gapsExist = true
      }
    }
  })
  return gapsExist
}

//the backed-end needs the last tier to have the maximum number so we use this function to add it in the last tier
//before sending the request
export const addMaxNumberToTiers = (array) => {
  array[array.length - 1].maximum_interval = Number.MAX_VALUE
  return array
}

// when creating a new bundle we chech on the entered batches to make sure that thier order is valid
export const checkBatchesOrder = (array) => {
  let valid = true
  array.forEach((batch, index, arr) => {
    if (arr.length - index !== 1) {
      if (batch.amount_of_hits > arr[index + 1].amount_of_hits) {
        valid = false
      }
    }
  })
  return valid
}

export const editFieldsInBundles = (bundles) => {
  return bundles?.map((bundle) => {
    return {
      ...bundle,
      pricing_type: bundle?.pricing_type?.toLowerCase(),
      bill_type: bundle?.bill_type?.toLowerCase().replace('_', ' '),
    }
  })
}

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result.substr(fileReader.result.indexOf(',') + 1))
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export const titleCase = (s) =>
  s
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase())
