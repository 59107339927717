import {
  DashboardOutlined,
  LineChartOutlined,
  DollarOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  ScanOutlined
} from '@ant-design/icons'
import { ADMIN_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
  {
    key: 'admin',
    path: `${ADMIN_PREFIX_PATH}`,
    title: 'sidenav.mainMenu',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'admin-dashboard',
        path: `${ADMIN_PREFIX_PATH}/dashboard`,
        title: 'adminSideNav.dashboard',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'admin-analytics',
        path: `${ADMIN_PREFIX_PATH}/analytic`,
        title: 'adminSideNav.analytic',
        icon: DollarOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: 'admin-reporting',
      //   path: `${ADMIN_PREFIX_PATH}/reporting`,
      //   title: 'adminSideNav.reporting',
      //   icon: FolderOpenOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: 'admin-sessions',
        path: `${ADMIN_PREFIX_PATH}/sessions`,
        title: 'adminSideNav.sessions',
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'admin-clients',
        path: `${ADMIN_PREFIX_PATH}/clients`,
        title: 'adminSideNav.clients',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'admin-transactions',
        path: `${ADMIN_PREFIX_PATH}/transactions`,
        title: 'adminSideNav.transactions',
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'admin-export-data',
        path: `${ADMIN_PREFIX_PATH}/export-data`,
        title: 'adminSideNav.export-data',
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'admin-export-transactions',
        path: `${ADMIN_PREFIX_PATH}/export-transactions`,
        title: 'adminSideNav.export-transactions',
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'admin-sanction-shield',
        path: `${ADMIN_PREFIX_PATH}/sanction`,
        title: 'adminSideNav.sanction',
        icon: ExclamationCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'admin-name-match-compare',
        path: `${ADMIN_PREFIX_PATH}/name-match`,
        title: 'adminSideNav.name-match',
        icon: ExclamationCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'admin-generic-ocr',
        path: `${ADMIN_PREFIX_PATH}/generic-ocr`,
        title: 'adminSideNav.generic_ocr',
        icon: ScanOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
]

const AdminnavigationConfig = [...dashBoardNavTree]

export default AdminnavigationConfig
