import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  HIDE_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  REFRESH_TOKEN,
  SIGNIN_ADMIN,
  AUTHENTICATED_ADMIN,
  SIGNOUT_ADMIN,
  SIGNOUT_SUCCESS_ADMIN,
  UNINITIALIZED_AUTHENTICATION
} from '../constants/Auth'

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user
  }
}

export const signInAdmin = (user) => {
  return {
    type: SIGNIN_ADMIN,
    payload: user
  }
}

export const uninitialized_authentication = (token) => {
  return {
    type: UNINITIALIZED_AUTHENTICATION,
    token
  }
}

export const authenticated = (token, account_type) => {
  return {
    type: AUTHENTICATED,
    payload: {token, account_type}
  }
}

export const authenticatedAdmin = (token) => {
  return {
    type: AUTHENTICATED_ADMIN,
    token
  }
}

export const signOut = () => {
  return {
    type: SIGNOUT
  }
}

export const signOutAdmin = () => {
  return {
    type: SIGNOUT_ADMIN
  }
}

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS
  }
}

export const signOutSuccessAdmin = () => {
  return {
    type: SIGNOUT_SUCCESS_ADMIN
  }
}

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user
  }
}

export const refreshToken = (token) => {
  return {
    type: REFRESH_TOKEN,
    payload: token
  }
}

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token
  }
}

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE
  }
}

export const signInWithGoogleAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token
  }
}

export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK
  }
}

export const signInWithFacebookAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token
  }
}

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message
  }
}

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE
  }
}

export const showLoading = () => {
  return {
    type: SHOW_LOADING
  }
}

export const hideLoading = () => {
  return {
    type: HIDE_LOADING
  }
}
