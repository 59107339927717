import {
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNOUT_SUCCESS_ADMIN,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  HIDE_LOADING,
  REFRESH_TOKEN,
  AUTHENTICATED_ADMIN,
  UNINITIALIZED_AUTHENTICATION
} from '../constants/Auth'
import { SIGNUP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'

const initState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem('USERNAME'),
  isInitialized: localStorage.getItem('isInitialized') === 'true',
  authenticated: !!localStorage.getItem('USERNAME'),
  isAdmin: localStorage.getItem('isAdmin') === 'true',
  account_type: localStorage.getItem('ACCOUNT_TYPE'),
}

const auth = (state = initState, action) => {
  switch (action.type) {
    case UNINITIALIZED_AUTHENTICATION:
      return {
        ...state,
        loading: false,
        redirect: `${SIGNUP_PREFIX_PATH}/user-details`,
        token: action.token,
        authenticated: true,
        isInitialized: false,
        isAdmin: false,
      }
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: '/app/pages/home',
        token: action.payload.token,
        authenticated: true,
        isInitialized: true,
        isAdmin: false,
        account_type: action.payload.account_type,
      }
    case AUTHENTICATED_ADMIN:
      return {
        ...state,
        loading: false,
        redirect: '/admin',
        token: action.token,
        authenticated: true,
        isInitialized: true,
        isAdmin: true,
      }
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      }
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false,
      }
    case REFRESH_TOKEN:
      return {
        ...state,
        token: action.token,
      }
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: '/',
        loading: false,
        authenticated: false,
        isAdmin: false,
        isInitialized: false,
        account_type: null,
      }
    }
    case SIGNOUT_SUCCESS_ADMIN: {
      return {
        ...state,
        token: null,
        redirect: `${AUTH_PREFIX_PATH}/login/admin`,
        loading: false,
        authenticated: false,
        isAdmin: false,
        isInitialized: false,
      }
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token,
      }
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case HIDE_LOADING: {
      return {
        ...state,
        loading: false,
      }
    }

    default:
      return state
  }
}

export default auth
