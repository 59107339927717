import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from 'components/shared-components/Loading'
import { AUTH_PREFIX_PATH , SIGNUP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover='page' />}>
      <Switch>
        <Route
          path={`${AUTH_PREFIX_PATH}/login`}
          exact
          component={lazy(() => import('./authentication/login'))}
        />
        <Route
          exact
          path={`${AUTH_PREFIX_PATH}/login/admin`}
          component={lazy(() => import('./authentication/login-2'))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/forgot-password`}
          component={lazy(() => import('./authentication/forgot-password'))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/reset-password`}
          component={lazy(() => import('./authentication/reset-password'))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/initialize-password/:token`}
          component={lazy(() => import('./authentication/initialize-password'))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/verify-email/:token`}
          component={lazy(() => import('./authentication/verify-email'))}
        />
        <Route
          exact
          path={`${SIGNUP_PREFIX_PATH}`}
          component={lazy(() => import('./registration/sign-up'))}
        />
        <Route
          exact
          path={`${SIGNUP_PREFIX_PATH}/otp-verification`}
          component={lazy(() => import('./registration/otp-verification'))}
        />
        <Route
          exact
          path={`${SIGNUP_PREFIX_PATH}/create-password`}
          component={lazy(() => import('./registration/create-password'))}
        />
        <Route
          exact
          path={`${SIGNUP_PREFIX_PATH}/name`}
          component={lazy(() => import('./registration/name'))}
        />
        <Route
          exact
          path={`${SIGNUP_PREFIX_PATH}/user-details`}
          component={lazy(() => import('./registration/user-details'))}
        />
        <Route
          exact
          path={`${SIGNUP_PREFIX_PATH}/phone-number`}
          component={lazy(() => import('./registration/phone-number'))}
        />
        <Route
          exact
          path={`${SIGNUP_PREFIX_PATH}/industry`}
          component={lazy(() => import('./registration/industry'))}
        />
        <Route
          exact
          path={`${SIGNUP_PREFIX_PATH}/role`}
          component={lazy(() => import('./registration/role'))}
        />
        <Route
          exact
          path={`${SIGNUP_PREFIX_PATH}/company-name`}
          component={lazy(() => import('./registration/company-name'))}
        />
        <Route
          exact
          path={`${SIGNUP_PREFIX_PATH}/company-website`}
          component={lazy(() => import('./registration/company-website'))}
        />
        <Route
          path={`${AUTH_PREFIX_PATH}/error-1`}
          component={lazy(() => import('./errors/error-page-1'))}
        />
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews
