import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import {
  GET_SSP_BUNDLE_SERVICES_SAGA,
  GET_DASHBOARD_SAGA,
  GET_PROFILE_DATA_SAGA,
  GET_USER_SESSIONS_SAGA,
  GET_USER_TRANSACTIONS_SAGA,
  GET_SSP_BUNDLE_ANALYTICS_SAGA,
  GET_SSP_SESSION_DETAILS_SAGA,
  CHANGE_SSP_PASSWORD_SAGA,
} from 'redux/constants/SSP'
import {
  getDashboard,
  getDashboardSaga,
  clearSSPData,
  getProfileData,
  getProfileDataSaga,
  getUserSessions,
  getUserSessionsSaga,
  getUserTransactions,
  getUserTransactionsSaga,
  getBundleServices,
  getBundleServicesSaga,
  getSspBundleAnalytics,
  getSspBundleAnalyticsSaga,
  getSspSessionDetials,
  getSspSessionDetialsSaga,
  changeSspPassword,
  changeSspPasswordSaga,
} from 'redux/actions/SSP'
import { hideLoading, showLoading, signOutSuccess } from 'redux/actions/Auth'
import sspServices from 'services/SSPservices'
import AuthService from 'services/AuthService'

export function* getDashboardSagaGen() {
  yield takeEvery(GET_DASHBOARD_SAGA, function* ({ payload }) {
    yield put(showLoading())
    try {
      const { data } = yield call(sspServices.getDashboard, payload)
      yield put(getDashboard(data, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield put(showLoading())
            yield call(AuthService.sspRefreshToken)
            yield put(getDashboardSaga(payload))
          } catch (e) {
            yield call(AuthService.logout)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccess())
            yield put(clearSSPData())
          }
        } else if (e.response.data) {
          yield put(getDashboard(e.response.data.message, true))
        } else {
          getDashboard('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getDashboard('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideLoading())
  })
}

export function* getSessionsSagaGen() {
  yield takeEvery(GET_USER_SESSIONS_SAGA, function* ({ payload }) {
    yield put(showLoading())
    try {
      const { data } = yield call(sspServices.getUserSessions, payload)
      yield put(getUserSessions(data.sessions, false, data.total))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield put(showLoading())
            yield call(AuthService.sspRefreshToken)
            yield put(getUserSessionsSaga(payload))
          } catch (e) {
            yield call(AuthService.logout)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccess())
            yield put(clearSSPData())
          }
        } else if (e.response.data) {
          yield put(getUserSessions(e.response.data.message, true))
        } else {
          getUserSessions('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getUserSessions('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideLoading())
  })
}

export function* getSessionDetailsSagaGen() {
  yield takeEvery(GET_SSP_SESSION_DETAILS_SAGA, function* ({ payload }) {
    yield put(showLoading())

    try {
      const { data } = yield call(sspServices.getSessionDetails, payload)
      yield put(getSspSessionDetials(data, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield put(showLoading())
            yield call(AuthService.sspRefreshToken)
            yield put(getSspSessionDetialsSaga(payload))
          } catch (e) {
            yield call(AuthService.logout)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccess())
            yield put(clearSSPData())
          }
        } else if (e.response.data) {
          yield put(getSspSessionDetials(e.response.data.message, true))
        } else {
          getSspSessionDetials('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getSspSessionDetials('Please make sure of your internet connection!', true))
      }
    }
    yield put(hideLoading())
  })
}

export function* getTransactionsSagaGen() {
  yield takeEvery(GET_USER_TRANSACTIONS_SAGA, function* ({ payload }) {
    yield put(showLoading())
    try {
      const { data } = yield call(sspServices.getUserTransactions, payload)
      yield put(getUserTransactions(data.transactions, false, data.total))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield put(showLoading())
            yield call(AuthService.sspRefreshToken)
            yield put(getUserTransactionsSaga(payload))
          } catch (e) {
            yield call(AuthService.logout)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccess())
            yield put(clearSSPData())
          }
        } else if (e.response.data) {
          yield put(getUserTransactions(e.response.data.message, true))
        } else {
          getUserTransactions('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getUserTransactions('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideLoading())
  })
}

export function* getBundleServicesSagaGen() {
  yield takeEvery(GET_SSP_BUNDLE_SERVICES_SAGA, function* ({ payload }) {
    try {
      const { data } = yield call(sspServices.getBundleServices, payload)
      yield put(getBundleServices(data.services, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield put(showLoading())
            yield call(AuthService.sspRefreshToken)
            yield put(getBundleServicesSaga(payload))
          } catch (e) {
            yield call(AuthService.logout)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccess())
            yield put(clearSSPData())
          }
        } else if (e.response.data) {
          yield put(getBundleServices(e.response.data.message, true))
        } else {
          getBundleServices('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getBundleServices('Please make sure of your internet connection!', true))
      }
    }
  })
}

export function* getSspBundleAnalyticsSagaGen() {
  yield takeEvery(GET_SSP_BUNDLE_ANALYTICS_SAGA, function* ({ payload }) {
    yield put(showLoading())
    try {
      const { data } = yield call(sspServices.getBundleAnalytics, payload)
      yield put(getSspBundleAnalytics(data, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield put(showLoading())
            yield call(AuthService.sspRefreshToken)
            yield put(getSspBundleAnalyticsSaga(payload))
          } catch (e) {
            yield call(AuthService.logout)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccess())
            yield put(clearSSPData())
          }
        } else if (e.response.data) {
          yield put(getSspBundleAnalytics(e.response.data.message, true))
        } else {
          getSspBundleAnalytics('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getSspBundleAnalytics('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideLoading())
  })
}

export function* changeSspPasswordSagaGen() {
  yield takeEvery(CHANGE_SSP_PASSWORD_SAGA, function* ({ payload }) {
    yield put(showLoading())
    try {
      const { data } = yield call(sspServices.changePassword, payload)
      yield put(changeSspPassword(data, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield put(showLoading())
            yield call(AuthService.sspRefreshToken)
            yield put(changeSspPasswordSaga(payload))
          } catch (e) {
            yield call(AuthService.logout)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccess())
            yield put(clearSSPData())
          }
        } else if (e.response.data) {
          yield put(changeSspPassword(e.response.data.message, true))
        } else {
          changeSspPassword('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(changeSspPassword('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideLoading())
  })
}

export function* getProfileSagaGen() {
  yield takeEvery(GET_PROFILE_DATA_SAGA, function* ({ payload }) {
    yield put(showLoading())
    try {
      const { data } = yield call(sspServices.getProfileData, payload)
      yield put(getProfileData(data, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield put(showLoading())
            yield call(AuthService.sspRefreshToken)
            yield put(getProfileDataSaga(payload))
          } catch (e) {
            yield call(AuthService.logout)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccess())
            yield put(clearSSPData())
          }
        } else if (e.response.data) {
          yield put(getProfileData(e.response.data.message, true))
        } else {
          getProfileData('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getProfileData('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideLoading())
  })
}

export default function* rootSaga() {
  yield all([
    fork(getDashboardSagaGen),
    fork(getSessionsSagaGen),
    fork(getSessionDetailsSagaGen),
    fork(getTransactionsSagaGen),
    fork(getSspBundleAnalyticsSagaGen),
    fork(getProfileSagaGen),
    fork(getBundleServicesSagaGen),
    fork(changeSspPasswordSagaGen),
  ])
}
