import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import {
  GET_ORGANIZTIONS_SAGA,
  GET_ORGANIZTION_BUNLDES_SAGA,
  GET_BUNDLE_SESSIONS_SAGA,
  GET_SESSION_DETAILS_SAGA,
  GET_FULL_IMAGE_SAGA,
  GET_DASHBOARD_ANALYTICS_SAGA,
  GET_BOXED_IMAGE_SAGA,
} from '../constants/AdminData'
import {
  getOrganiztions,
  showAdminLaoding,
  hideAdminLaoding,
  getOrganiztionBunldes,
  getBundleSessions,
  clearAdminData,
  showSessionsLoading,
  hideSessionsLoading,
  getSessionDetails,
  getFullImage,
  getBoxedImage,
  showFullImageLaoding,
  hideFullImageLaoding,
  getDashboardAnalytics,
  getDashboardAnalyticsSaga,
  getOrganiztionsSaga,
  getOrganiztionBunldesSaga,
  getBundleSessionsSaga,
  getSessionDetailsSaga,
  getFullImageSaga,
  hideBoxedImageLoading,
  showBoxedImageLoading,
} from '../actions/AdminData'
import { signOutSuccessAdmin } from 'redux/actions/Auth'
import AdminServices from 'services/AdminServices'
import AuthService from 'services/AuthService'

export function* getDashboardAnalyticsSagaGen() {
  yield takeEvery(GET_DASHBOARD_ANALYTICS_SAGA, function* ({ payload }) {
    yield put(showAdminLaoding())
    try {
      const { data } = yield call(AdminServices.getDashboardAnalytics, payload)
      yield put(getDashboardAnalytics(data, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            // yield put(showAdminLaoding())
            yield call(AuthService.adminRefreshToken)
            yield put(getDashboardAnalyticsSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(getDashboardAnalytics(e.response.data.message, true))
        } else {
          getDashboardAnalytics('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getDashboardAnalytics('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideAdminLaoding())
  })
}

export function* getOrganiztionsSagaGen() {
  yield takeEvery(GET_ORGANIZTIONS_SAGA, function* ({ page, page_size, name }) {
    yield put(showAdminLaoding())
    try {
      const { data } = yield call(AdminServices.getOrganiztions, { page, page_size, name })
      yield put(getOrganiztions(data.organizations, false, data.count))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(getOrganiztionsSaga())
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(getOrganiztions(e.response.data.detail, true))
        } else {
          getOrganiztions('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getOrganiztions('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideAdminLaoding())
  })
}

export function* getOrganiztionBunldesSagaGen() {
  yield takeEvery(GET_ORGANIZTION_BUNLDES_SAGA, function* ({ payload: organizationID }) {
    try {
      const { data } = yield call(AdminServices.getOrganiztionsBundles, {
        organization_id: organizationID,
      })
      yield put(getOrganiztionBunldes(data.bundles, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(getOrganiztionBunldesSaga(organizationID))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(getOrganiztionBunldes(e.response.data.detail, true))
        } else {
          getOrganiztionBunldes('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getOrganiztionBunldes('Please make sure of your internet connection!', true))
      }
    }
  })
}

export function* getBundleSessionsSagaGen() {
  yield takeEvery(GET_BUNDLE_SESSIONS_SAGA, function* ({ payload }) {
    yield put(showSessionsLoading())
    try {
      const { data } = yield call(AdminServices.getBundleSessions, payload)
      yield put(getBundleSessions(data.sessions, false, data.total))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(getBundleSessionsSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(getBundleSessions(e.response.data.message, true))
        } else {
          getBundleSessions('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getBundleSessions('Please make sure of your internet connection!', true))
      }
    }
    yield put(hideSessionsLoading())
  })
}

export function* getSessionDetailsSagaGen() {
  yield takeEvery(GET_SESSION_DETAILS_SAGA, function* ({ payload }) {
    yield put(showAdminLaoding())

    try {
      const { data } = yield call(AdminServices.getSessionDetails, {
        bundle_id: payload.bundleId,
        session_id: payload.sessionId,
      })
      yield put(getSessionDetails(data, false, data.total))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(getSessionDetailsSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(getSessionDetails(e.response.data.message, true))
        } else {
          getSessionDetails('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getSessionDetails('Please make sure of your internet connection!', true))
      }
    }
    yield put(hideAdminLaoding())
  })
}

export function* getFullImageSagaGen() {
  yield takeEvery(GET_FULL_IMAGE_SAGA, function* ({ payload }) {
    yield put(showFullImageLaoding())
    try {
      const { data } = yield call(AdminServices.getOriginalImg, payload)
      yield put(getFullImage(data.img, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield put(showFullImageLaoding())
            yield call(AuthService.adminRefreshToken)
            yield put(getFullImageSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(getFullImage(e.response.data.message, true))
        } else {
          getFullImage('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getFullImage('Please make sure of your internet connection!', true))
      }
    }
    yield put(hideFullImageLaoding())
  })
}
export function* getBoxedImageSagaGen() {
  yield takeEvery(GET_BOXED_IMAGE_SAGA, function* ({ payload }) {
    yield put(showBoxedImageLoading())
    try {
      const { data } = yield call(AdminServices.getBoxedImg, payload)
      yield put(getBoxedImage(data.img, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield put(showBoxedImageLoading())
            yield call(AuthService.adminRefreshToken)
            //yield put(getBoxedImageSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(getBoxedImage(e.response.data.message, true))
        } else {
          getBoxedImage('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getBoxedImage('Please make sure of your internet connection!', true))
      }
    }
    yield put(hideBoxedImageLoading())
  })
}

export default function* rootSaga() {
  yield all([
    fork(getOrganiztionsSagaGen),
    fork(getOrganiztionBunldesSagaGen),
    fork(getBundleSessionsSagaGen),
    fork(getSessionDetailsSagaGen),
    fork(getFullImageSagaGen),
    fork(getBoxedImageSagaGen),
    fork(getDashboardAnalyticsSagaGen),
  ])
}
