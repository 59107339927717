import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import AdminSideNav from 'components/layout-components/AdminSideNav'
import Loading from 'components/shared-components/Loading'
import AdminMobileNav from 'components/layout-components/AdminMobileNav'
import HeaderNav from 'components/layout-components/HeaderNav'
import PageHeader from 'components/layout-components/PageHeader'
import Footer from 'components/layout-components/Footer'
import AdminViews from 'views/admin-views'
import { Layout, Grid } from 'antd'
// import navigationConfig from 'configs/NavigationConfig'
import AdminnavigationConfig from 'configs/AdminNavigationConfig'
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR
} from 'constants/ThemeConstant'
import utils from 'utils'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import AdminTopNav from 'components/layout-components/AdminTopNav'
import { onSwitchTheme } from 'redux/actions'

const { Content } = Layout
const { useBreakpoint } = Grid

export const AdminLayout = ({ navCollapsed, navType, location, direction, onSwitchTheme }) => {
  const { switcher, themes } = useThemeSwitcher()
  const currentRouteInfo = utils.getRouteInfo(AdminnavigationConfig, location?.pathname)
  const screens = utils.getBreakPoint(useBreakpoint())
  const isMobile = screens.length === 0 ? false : !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const { status } = useThemeSwitcher()

  useEffect(() => {
    const toggleTheme = () => {
      onSwitchTheme('dark')
      switcher({ theme: themes.dark })
    }

    toggleTheme()
  }, [onSwitchTheme, themes, switcher])

  if (status === 'loading') {
    return <Loading cover='page' />
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() }
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() }
    }
    return { paddingLeft: getLayoutGutter() }
  }

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <AdminTopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className='app-container'>
        {isNavSide && !isMobile ? <AdminSideNav routeInfo={currentRouteInfo} /> : null}
        <Layout className='app-layout' style={getLayoutDirectionGutter()}>
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <AdminViews />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <AdminMobileNav />}
    </Layout>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme
  return { navCollapsed, navType, locale }
}

const mapDispatchToProps = {
  onSwitchTheme
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AdminLayout))
