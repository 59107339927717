import {
  GET_ORGANIZTIONS,
  GET_ORGANIZTIONS_SAGA,
  GET_ORGANIZTION_BUNLDES,
  GET_ORGANIZTION_BUNLDES_SAGA,
  GET_BUNDLE_SESSIONS,
  GET_BUNDLE_SESSIONS_SAGA,
  SHOW_SESSIONS_LOADING,
  HIDE_SESSIONS_LOADING,
  SHOW_ADMIN_LOADING,
  HIDE_ADMIN_LOADING,
  CLEAR_ADMIN_DATA,
  GET_SESSION_DETAILS_SAGA,
  GET_SESSION_DETAILS,
  GET_FULL_IMAGE,
  GET_FULL_IMAGE_SAGA,
  SHOW_FULL_IMAGE__LOADING,
  HIDE_FULL_IMAGE__LOADING,
  HIDE_BOXED_IMAGE_LOADING,
  GET_DASHBOARD_ANALYTICS_SAGA,
  GET_DASHBOARD_ANALYTICS,
  CREATE_ORGANIZATON_SAGA,
  CREATE_ORGANIZATON,
  UPDATE_ORGANIZATON_SAGA,
  UPDATE_ORGANIZATON,
  GET_ORGANIZATON_DETAILS_SAGA,
  GET_ORGANIZATON_DETAILS,
  GET_BUNLDE_DETAILS_SAGA,
  GET_BUNLDE_DETAILS,
  GET_VALIFY_SERVICES_SAGA,
  GET_VALIFY_SERVICES,
  CREATE_BUNDLE_SAGA,
  CREATE_BUNDLE,
  GET_BUNDLE_ANALYTICS_SAGA,
  GET_BUNDLE_ANALYTICS,
  GET_BUNDLE_TRANSACTIONS_SAGA,
  GET_BUNDLE_TRANSACTIONS,
  GET_BUNDLE_SERVICES,
  GET_BUNDLE_SERVICES_SAGA,
  SHOW_SERVICES_LOADING,
  HIDE_SERVICES_LOADING,
  SHOW_TRANSACTION_LOADING,
  HIDE_TRANSACTION_LOADING,
  GET_TRANSACTION_DETAILS_SAGA,
  GET_TRANSACTION_DETAILS,
  EVENT_SUCCEDED,
  GET_BOXED_IMAGE_SAGA,
  GET_BOXED_IMAGE,
  SHOW_BOXED_IMAGE_LOADING,
  EXPORT_DATA_SAGA,
  EXPORT_DATA,
  EXPORT_TRANSACTIONS_SAGA,
  EXPORT_TRANSACTIONS,
} from 'redux/constants/AdminData'

export function getDashboardAnalyticsSaga(data) {
  return {
    type: GET_DASHBOARD_ANALYTICS_SAGA,
    payload: data,
  }
}

export function getDashboardAnalytics(payload, isFailed) {
  return {
    type: GET_DASHBOARD_ANALYTICS,
    payload,
    isFailed,
  }
}

export function getValifyServicesSaga() {
  return {
    type: GET_VALIFY_SERVICES_SAGA,
  }
}

export function getValifyServices(payload, isFailed) {
  return {
    type: GET_VALIFY_SERVICES,
    payload,
    isFailed,
  }
}

export function createOrganiztionSaga(data) {
  return {
    type: CREATE_ORGANIZATON_SAGA,
    payload: data,
  }
}

export function createOrganiztion(payload, isFailed) {
  return {
    type: CREATE_ORGANIZATON,
    payload,
    isFailed,
  }
}

export function createBundleSaga(data) {
  return {
    type: CREATE_BUNDLE_SAGA,
    payload: data,
  }
}

export function createBundle(payload, isFailed) {
  return {
    type: CREATE_BUNDLE,
    payload,
    isFailed,
  }
}

export function exportDataSaga(data) {
  return {
    type: EXPORT_DATA_SAGA,
    payload: data,
  }
}
export function exportData(payload, isFailed) {
  return {
    type: EXPORT_DATA,
    payload,
    isFailed,
  }
}
export function exportTransactionsSaga(data) {
  return {
    type: EXPORT_TRANSACTIONS_SAGA,
    payload: data,
  }
}
export function exportTransactions(payload, isFailed) {
  return {
    type: EXPORT_TRANSACTIONS,
    payload,
    isFailed,
  }
}

export function getOrganiztionDetailsSaga(data) {
  return {
    type: GET_ORGANIZATON_DETAILS_SAGA,
    payload: data,
  }
}

export function getOrganiztionDetails(payload, isFailed) {
  return {
    type: GET_ORGANIZATON_DETAILS,
    payload,
    isFailed,
  }
}

export function eventHappened() {
  return {
    type: EVENT_SUCCEDED,
  }
}

export function getBundleDetailsSaga(data) {
  return {
    type: GET_BUNLDE_DETAILS_SAGA,
    payload: data,
  }
}

export function getBundleDetails(payload, isFailed) {
  return {
    type: GET_BUNLDE_DETAILS,
    payload,
    isFailed,
  }
}

export function getBundleAnalyticsSaga(data) {
  return {
    type: GET_BUNDLE_ANALYTICS_SAGA,
    payload: data,
  }
}

export function getBundleAnalytics(payload, isFailed) {
  return {
    type: GET_BUNDLE_ANALYTICS,
    payload,
    isFailed,
  }
}
export function updateOrganiztionSaga(data) {
  return {
    type: UPDATE_ORGANIZATON_SAGA,
    payload: data,
  }
}

export function updateOrganiztion(isFailed) {
  return {
    type: UPDATE_ORGANIZATON,
    isFailed,
  }
}

export function getOrganiztionsSaga(page, page_size, name) {
  return {
    type: GET_ORGANIZTIONS_SAGA,
    page,
    page_size,
    name,
  }
}

export function getOrganiztions(payload, isFailed, total) {
  return {
    type: GET_ORGANIZTIONS,
    payload,
    isFailed,
    totalOrganizations: total,
  }
}

export function getOrganiztionBunldesSaga(organizationID) {
  return {
    type: GET_ORGANIZTION_BUNLDES_SAGA,
    payload: organizationID,
  }
}

export function getOrganiztionBunldes(payload, isFailed) {
  return {
    type: GET_ORGANIZTION_BUNLDES,
    isFailed,
    payload,
  }
}

export function getBundleServicesSaga(data) {
  return {
    type: GET_BUNDLE_SERVICES_SAGA,
    payload: data,
  }
}

export function getBundleServices(payload, isFailed) {
  return {
    type: GET_BUNDLE_SERVICES,
    isFailed,
    payload,
  }
}

export function getBundleSessionsSaga(data) {
  return {
    type: GET_BUNDLE_SESSIONS_SAGA,
    payload: data,
  }
}

export function getBundleSessions(payload, isFailed, totalSessions) {
  return {
    type: GET_BUNDLE_SESSIONS,
    isFailed,
    payload,
    totalSessions,
  }
}

export function getBundleTransactionsSaga(data) {
  return {
    type: GET_BUNDLE_TRANSACTIONS_SAGA,
    payload: data,
  }
}

export function getBundleTransactions(payload, isFailed, totalTransactions) {
  return {
    type: GET_BUNDLE_TRANSACTIONS,
    isFailed,
    payload,
    totalTransactions,
  }
}

export function getSessionDetailsSaga(data) {
  return {
    type: GET_SESSION_DETAILS_SAGA,
    payload: data,
  }
}

export function getSessionDetails(payload, isFailed) {
  return {
    type: GET_SESSION_DETAILS,
    isFailed,
    payload,
  }
}

export function getTransactionDetailsSaga(data) {
  return {
    type: GET_TRANSACTION_DETAILS_SAGA,
    payload: data,
  }
}

export function getTransactionDetails(payload, isFailed) {
  return {
    type: GET_TRANSACTION_DETAILS,
    isFailed,
    payload,
  }
}

export function getFullImageSaga(data) {
  return {
    type: GET_FULL_IMAGE_SAGA,
    payload: data,
  }
}

export function getFullImage(data, isFailed) {
  return {
    type: GET_FULL_IMAGE,
    payload: data,
    isFailed,
  }
}
export function getBoxedImage(data, isFailed) {
  return {
    type: GET_BOXED_IMAGE,
    payload: data,
    isFailed: isFailed,
  }
}
export function getBoxedImageSaga(data) {
  return {
    type: GET_BOXED_IMAGE_SAGA,
    payload: data,
  }
}

export function showSessionsLoading() {
  return {
    type: SHOW_SESSIONS_LOADING,
  }
}

export function hideSessionsLoading() {
  return {
    type: HIDE_SESSIONS_LOADING,
  }
}

export function showServicesLoading() {
  return {
    type: SHOW_SERVICES_LOADING,
  }
}

export function hideServicesLoading() {
  return {
    type: HIDE_SERVICES_LOADING,
  }
}

export function showAdminLaoding() {
  return {
    type: SHOW_ADMIN_LOADING,
  }
}

export function hideAdminLaoding() {
  return {
    type: HIDE_ADMIN_LOADING,
  }
}

export function showTransactionLaoding() {
  return {
    type: SHOW_TRANSACTION_LOADING,
  }
}

export function hideTransactionLaoding() {
  return {
    type: HIDE_TRANSACTION_LOADING,
  }
}

export function showFullImageLaoding() {
  return {
    type: SHOW_FULL_IMAGE__LOADING,
  }
}
export function showBoxedImageLoading() {
  return {
    type: SHOW_BOXED_IMAGE_LOADING,
  }
}

export function hideFullImageLaoding() {
  return {
    type: HIDE_FULL_IMAGE__LOADING,
  }
}
export function hideBoxedImageLoading() {
  return {
    type: HIDE_BOXED_IMAGE_LOADING,
  }
}

export function clearAdminData() {
  return {
    type: CLEAR_ADMIN_DATA,
  }
}
