import axios from 'axios'

const AdminServices = {}

AdminServices.getOrganiztions = function ({ page, page_size, name }) {
  const config = {
    method: 'get',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/organizations/${
          name ? `?name=${name}` : page && page_size ? `?page_size=${page_size}&page=${page}` : ''
        }`
      : `/admin-portal-backend/v1/valify/organizations/${
          name ? `?name=${name}` : page && page_size ? `?page_size=${page_size}&page=${page}` : ''
        }`,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  }

  return axios(config)
}

AdminServices.getValifyServices = function () {
  const config = {
    method: 'get',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/services/`
      : '/admin-portal-backend/v1/valify/services/',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  }

  return axios(config)
}

AdminServices.getDashboardAnalytics = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/admin-analytics/`
      : '/admin-portal-backend/v1/valify/admin-analytics/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

AdminServices.getOrganiztionsBundles = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/organization-bundles/`
      : '/admin-portal-backend/v1/valify/organization-bundles/ ',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

AdminServices.getBundleSessions = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/bundle-sessions/`
      : '/admin-portal-backend/v1/valify/bundle-sessions/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

AdminServices.getBundleTransactions = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/bundle-transactions/`
      : '/admin-portal-backend/v1/valify/bundle-transactions/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

AdminServices.getBundleServices = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/bundle-services/`
      : '/admin-portal-backend/v1/valify/bundle-services/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

AdminServices.getSessionDetails = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/bundle-session-details/`
      : '/admin-portal-backend/v1/valify/bundle-session-details/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

AdminServices.getTransactionDetails = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/bundle-transaction-details/`
      : '/admin-portal-backend/v1/valify/bundle-transaction-details/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

AdminServices.getOriginalImg = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/original-image/`
      : '/admin-portal-backend/v1/valify/original-image/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}
AdminServices.getBoxedImg = function (data) {
  var config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/boxed-image/`
      : '/admin-portal-backend/v1/valify/boxed-image/',
    headers: {
      'Content-Type': 'application/json',
    },
    data: data,
    withCredentials: true,
  }
  return axios(config)
}

AdminServices.exportData = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/export-images/`
      : '/admin-portal-backend/v1/valify/export-images/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }
  return axios(config)
}

AdminServices.exportTransactions = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/export-transactions/`
      : '/admin-portal-backend/v1/valify/export-transactions/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }
  return axios(config)
}


AdminServices.createBundle = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/bundles/create/`
      : '/admin-portal-backend/v1/valify/bundles/create/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

AdminServices.createOrganiztion = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/organizations/create/`
      : '/admin-portal-backend/v1/valify/organizations/create/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

AdminServices.updateOrganiztion = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/organizations/update/`
      : '/admin-portal-backend/v1/valify/organizations/update/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

AdminServices.getOrganiztionDetails = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/organizations/details/`
      : '/admin-portal-backend/v1/valify/organizations/details/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

AdminServices.getBundleDetails = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/bundles/details/`
      : '/admin-portal-backend/v1/valify/bundles/details/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

AdminServices.getBundleAnalytics = function (data) {
  const config = {
    method: 'post',
    url: process.env.REACT_APP_DOMAIN_NAME
      ? `${process.env.REACT_APP_DOMAIN_NAME}/admin-portal-backend/v1/valify/bundle-analytics/`
      : '/admin-portal-backend/v1/valify/bundle-analytics/',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    withCredentials: true,
  }

  return axios(config)
}

export default AdminServices
