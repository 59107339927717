// import { HIDEERRORMESSAGE, SHOWERRORMESSAGE } from 'redux/constants/Data'
import {
  GET_DASHBOARD,
  CLEAR_SSP_DATA,
  GET_PROFILE,
  GET_USER_SESSIONS,
  GET_USER_TRANSACTIONS,
  GET_BUNDLE_SERVICES,
  GET_SSP_BUNDLE_ANALYTICS,
  GET_SSP_SESSION_DETAILS,
  CHANGE_SSP_PASSWORD,
  RESET_SSP_CHANGED_PASSWORD,
} from 'redux/constants/SSP'

const initState = {
  dashboard: {},
  profile: {},
  sessions: {},
  totalSessions: '',
  transactions: {},
  totalTransactions: '',
  bundleServices: [],
  bundleAnalytics: {},
  sessionDetails: {},
  changedPassword: '',
}

const ssp = (state = initState, action) => {
  switch (action.type) {
    case GET_DASHBOARD:
      return {
        ...state,
        dashboard: action.isFailed ? { error: action.payload } : action.payload,
      }
    case GET_BUNDLE_SERVICES:
      return {
        ...state,
        bundleServices: action.isFailed ? { error: action.payload } : action.payload,
      }
    case GET_SSP_BUNDLE_ANALYTICS:
      return {
        ...state,
        bundleAnalytics: action.isFailed ? { error: action.payload } : action.payload,
      }
    case GET_USER_SESSIONS:
      return {
        ...state,
        sessions: action.isFailed ? { error: action.payload } : action.payload,
        totalSessions: action.isFailed ? '' : action.totalSessions,
      }
    case GET_SSP_SESSION_DETAILS:
      return {
        ...state,
        sessionDetails: action.isFailed ? { error: action.payload } : action.payload,
      }
    case GET_USER_TRANSACTIONS:
      return {
        ...state,
        transactions: action.isFailed ? { error: action.payload } : action.payload,
        totalTransactions: action.isFailed ? '' : action.totalTransactions,
      }
    case CHANGE_SSP_PASSWORD:
      return {
        ...state,
        changedPassword: action.isFailed ? { error: action.payload } : true,
      }
    case RESET_SSP_CHANGED_PASSWORD:
      return {
        ...state,
        changedPassword: '',
      }
    case GET_PROFILE:
      return {
        ...state,
        profile: action.isFailed ? { error: action.payload } : action.payload,
      }
    case CLEAR_SSP_DATA:
      return initState

    default:
      return state
  }
}

export default ssp
