import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import AuthViews from 'views/auth-views'
import Loading from 'components/shared-components/Loading'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { onSwitchTheme } from 'redux/actions'
import { connect } from 'react-redux'

export const AuthLayout = ({ onSwitchTheme }) => {
  const { status } = useThemeSwitcher()
  const { switcher, themes } = useThemeSwitcher()

  useEffect(() => {
    const toggleTheme = () => {
      onSwitchTheme('light')
      switcher({ theme: themes.light })
    }

    toggleTheme()
  }, [switcher, themes, onSwitchTheme])

  if (status === 'loading') {
    return <Loading cover='page' />
  }

  return (
    <div className='auth-container'>
      <Switch>
        <Route path='' component={AuthViews} />
      </Switch>
    </div>
  )
}

const mapStateToProps = ({ theme }) => {
  const { currentTheme } = theme
  return { currentTheme }
}

const mapDispatchToProps = {
  onSwitchTheme
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout)
