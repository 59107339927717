import {
  DashboardOutlined,
  AppstoreOutlined,
  FolderOpenOutlined,
  BlockOutlined,
  UserOutlined,
  ScanOutlined,
  ProfileOutlined,
  CloudUploadOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
  {
    key: 'dashboard',
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: 'sidenav.mainMenu',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboard-default',
        path: `${APP_PREFIX_PATH}/pages/home`,
        title: 'sidenav.mainMenu.dashboard',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'dashboard-documentation',
        path: `${APP_PREFIX_PATH}/pages`,
        title: 'sidenav.mainMenu.documentaion',
        icon: FolderOpenOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'dashboard-api-docs',
            path: `${APP_PREFIX_PATH}/pages/api`,
            title: 'API Documentation',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'dashboard-sdk-docs',
            path: `${APP_PREFIX_PATH}/pages/sdk`,
            title: 'SDK Documentation',
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'dashboard-my-bundles',
        path: `${APP_PREFIX_PATH}/pages/bundle`,
        title: 'sidenav.mainMenu.myBundles',
        icon: BlockOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'try-it-out',
        path: `${APP_PREFIX_PATH}/pages/egnid-ocr`,
        title: 'sidenav.mainMenu.tryIt',
        icon: ScanOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'identity-validation',
            path: `${APP_PREFIX_PATH}/pages/egnid-ocr`,
            title: 'sidenav.mainMenu.tryIt.egnidOcr',
            icon: ProfileOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: 'sanction-list',
        path: `${APP_PREFIX_PATH}/pages/sanction-list-upload`,
        title: 'sidenav.mainMenu.sanction-lists',
        icon: WarningOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: 'sanction-list-upload',
            path: `${APP_PREFIX_PATH}/pages/sanction-list-upload`,
            title: 'sidenav.mainMenu.sanction-lists.sanction-list-upload',
            icon: CloudUploadOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
]

const appsNavTree = [
  {
    key: 'apps',
    path: `${APP_PREFIX_PATH}/apps`,
    title: 'sidenav.accInfo',
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'apps-mail',
        path: `${APP_PREFIX_PATH}/pages/profile`,
        title: 'sidenav.accInfo.myAccount',
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
]

const navigationConfig = [...dashBoardNavTree, ...appsNavTree]

export default navigationConfig
