import React from 'react'
import { Provider } from 'react-redux'
import store from './redux/store'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Views from './views'

import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import { THEME_CONFIG } from './configs/AppConfig'
import { GoogleOAuthProvider } from '@react-oauth/google';
// import { Toaster } from "react-hot-toast";
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_ID);

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`
}

function App () {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <div className='App'>
      {/* <Toaster
        position="top-center"
        toastOptions={{
          style: {
            fontSize: "1.5rem",
          },
        }}
      /> */}
      <Provider store={store}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={THEME_CONFIG.currentTheme}
          insertionPoint='styles-insertion-point'
        >
          <Router basename='/'>
            <Switch>
              <Route path='/' component={Views} />
            </Switch>
          </Router>
        </ThemeSwitcherProvider>
      </Provider>
    </div>
    </GoogleOAuthProvider>
  )
}

export default App
