import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import {
  CREATE_BUNDLE_SAGA,
  EXPORT_DATA_SAGA,
  EXPORT_TRANSACTIONS_SAGA,
  GET_BUNDLE_ANALYTICS_SAGA,
  GET_BUNDLE_SERVICES_SAGA,
  GET_BUNLDE_DETAILS_SAGA,
  GET_VALIFY_SERVICES_SAGA,
} from '../constants/AdminData'
import {
  showAdminLaoding,
  hideAdminLaoding,
  clearAdminData,
  getBundleDetails,
  getBundleDetailsSaga,
  getValifyServices,
  getValifyServicesSaga,
  createBundle,
  createBundleSaga,
  showSessionsLoading,
  hideSessionsLoading,
  getBundleAnalytics,
  getBundleAnalyticsSaga,
  getBundleServices,
  showServicesLoading,
  hideServicesLoading,
  exportData,
  exportDataSaga,
  exportTransactions,
  exportTransactionsSaga,
} from '../actions/AdminData'
import { signOutSuccessAdmin } from 'redux/actions/Auth'
import AdminServices from 'services/AdminServices'
import AuthService from 'services/AuthService'
import { message } from 'antd'

export function* getValifyServicesSagaGen() {
  yield takeEvery(GET_VALIFY_SERVICES_SAGA, function* ({ payload }) {
    // yield put(showAdminLaoding())
    try {
      const { data } = yield call(AdminServices.getValifyServices, payload)
      yield put(getValifyServices(data.services, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(getValifyServicesSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            localStorage.removeItem('BUNDLES')
            localStorage.removeItem('showingSessions')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(getValifyServices(e.response.data.message, true))
        } else {
          getValifyServices('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getValifyServices('Please make sure of your internet connection!', true))
      }
    }

    // yield put(hideAdminLaoding())
  })
}

export function* getBundleServicesSagaGen() {
  yield takeEvery(GET_BUNDLE_SERVICES_SAGA, function* ({ payload }) {
    yield put(showServicesLoading())
    try {
      const { data } = yield call(AdminServices.getBundleServices, payload)
      yield put(getBundleServices(data.services, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(getBundleServices(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(getBundleServices(e.response.data.message, true))
        } else {
          getBundleServices('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getBundleServices('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideServicesLoading())
  })
}

export function* getBundleDetailsSagaGen() {
  yield takeEvery(GET_BUNLDE_DETAILS_SAGA, function* ({ payload }) {
    yield put(showAdminLaoding())
    try {
      const { data } = yield call(AdminServices.getBundleDetails, payload)
      yield put(getBundleDetails(data, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(getBundleDetailsSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(getBundleDetails(e.response.data.message, true))
        } else {
          getBundleDetails('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getBundleDetails('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideAdminLaoding())
  })
}

export function* getBundleAnalyticsSagaGen() {
  yield takeEvery(GET_BUNDLE_ANALYTICS_SAGA, function* ({ payload }) {
    yield put(showSessionsLoading())
    try {
      const { data } = yield call(AdminServices.getBundleAnalytics, payload)
      yield put(getBundleAnalytics(data, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(getBundleAnalyticsSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(getBundleAnalytics(e.response.data.message, true))
        } else {
          getBundleAnalytics('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getBundleAnalytics('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideSessionsLoading())
  })
}

export function* createBundleSagaGen() {
  yield takeEvery(CREATE_BUNDLE_SAGA, function* ({ payload }) {
    yield put(showSessionsLoading())
    try {
      const { data } = yield call(AdminServices.createBundle, payload)
      yield put(createBundle(data, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(createBundleSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(createBundle(e.response.data.message, true))
        } else {
          createBundle('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(createBundle('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideSessionsLoading())
  })
}

export function* exportDataSagaGen() {
  yield takeEvery(EXPORT_DATA_SAGA, function* ({ payload }) {
    yield put(showSessionsLoading())
    try {
      const { data } = yield call(AdminServices.exportData, payload)
      message.success('You Will Recieve an Email Shortly!')
      yield put(exportData(data, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(exportDataSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(exportData(e.response.data.message, true))
        } else {
          exportData('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(exportData('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideSessionsLoading())
  })
}
export function* exportTransactionsGen() {
  yield takeEvery(EXPORT_TRANSACTIONS_SAGA, function* ({ payload }) {
    yield put(showSessionsLoading())
    try {
      const { data } = yield call(AdminServices.exportTransactions, payload)
      message.success('You Will Recieve an Email Shortly!')
      yield put(exportTransactions(data, false))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(exportTransactionsSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(exportTransactions(e.response.data.message, true))
        } else {
          exportTransactions('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(exportTransactions('Please make sure of your internet connection!', true))
      }
    }

    yield put(hideSessionsLoading())
  })
}
export default function* rootSaga() {
  yield all([
    fork(getBundleDetailsSagaGen),
    fork(getValifyServicesSagaGen),
    fork(createBundleSagaGen),
    fork(getBundleAnalyticsSagaGen),
    fork(getBundleServicesSagaGen),
    fork(exportDataSagaGen),
    fork(exportTransactionsGen),
  ])
}
