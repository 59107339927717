import {
  GET_ORGANIZTIONS,
  SHOW_ADMIN_LOADING,
  HIDE_ADMIN_LOADING,
  GET_ORGANIZTION_BUNLDES,
  GET_BUNDLE_SESSIONS,
  SHOW_SESSIONS_LOADING,
  HIDE_SESSIONS_LOADING,
  CLEAR_ADMIN_DATA,
  GET_SESSION_DETAILS,
  GET_FULL_IMAGE,
  SHOW_FULL_IMAGE__LOADING,
  HIDE_FULL_IMAGE__LOADING,
  GET_DASHBOARD_ANALYTICS,
  CREATE_ORGANIZATON,
  GET_ORGANIZATON_DETAILS,
  GET_BUNLDE_DETAILS,
  GET_VALIFY_SERVICES,
  CREATE_BUNDLE,
  GET_BUNDLE_ANALYTICS,
  GET_BUNDLE_TRANSACTIONS,
  GET_BUNDLE_SERVICES,
  SHOW_SERVICES_LOADING,
  HIDE_SERVICES_LOADING,
  SHOW_TRANSACTION_LOADING,
  HIDE_TRANSACTION_LOADING,
  GET_TRANSACTION_DETAILS,
  EVENT_SUCCEDED,
  GET_BOXED_IMAGE,
  SHOW_BOXED_IMAGE_LOADING,
  HIDE_BOXED_IMAGE_LOADING,
  EXPORT_DATA,
  EXPORT_TRANSACTIONS,
} from 'redux/constants/AdminData'
import { message } from 'antd'

const initState = {
  loading: false,
  organiztions: {},
  organiztionBundles: {},
  sessionDetails: {},
  totalSessions: 0,
  sessionsLoading: false,
  fullImage: '',
  fullImageLoading: '',
  boxedImage: '',
  boxedImageLoading: '',
  createdOrganizationId: '',
  organizationDetails: '',
  bundleDetails: '',
  valifyServices: '',
  createdBundleId: '',
  bundleAnalytics: '',
  servicesLoading: false,
  transactionLoading: false,
  transactionDetails: {},
  totalClients: '',
  eventHappened: false,
  exportData: { success: false, error: '' },
  exportTransactions: { success: false, error: '' },
}

const AdminData = (state = initState, action) => {
  switch (action.type) {
    case CLEAR_ADMIN_DATA:
      return {
        loading: false,
        organiztions: {},
        organiztionBundles: {},
        sessionDetails: {},
        totalSessions: 0,
        sessionsLoading: false,
        fullImage: '',
        fullImageLoading: '',
        dashboardAnalytics: {},
        createdOrganizationId: '',
        organizationDetails: '',
        bundleDetails: '',
        valifyServices: '',
        createdBundleId: '',
        bundleAnalytics: '',
        servicesLoading: false,
        transactionLoading: false,
        transactionDetails: {},
        totalClients: '',
        eventHappened: false,
        exportData: { success: false, error: '' },
        exportTransactions: { success: false, error: '' },
      }
    case GET_DASHBOARD_ANALYTICS:
      return {
        ...state,
        dashboardAnalytics: action.isFailed ? { error: action.payload } : action.payload,
      }
    case GET_ORGANIZTIONS:
      return {
        ...state,
        organiztions: action.isFailed ? { error: action.payload } : action.payload,
        totalClients: action.isFailed ? '' : action.totalOrganizations,
      }
    case GET_ORGANIZATON_DETAILS:
      return {
        ...state,
        organizationDetails: action.isFailed ? { error: action.payload } : action.payload,
      }
    case SHOW_ADMIN_LOADING:
      return {
        ...state,
        loading: true,
      }
    case HIDE_ADMIN_LOADING:
      return {
        ...state,
        loading: false,
      }
    case SHOW_SESSIONS_LOADING:
      return {
        ...state,
        sessionsLoading: true,
      }
    case HIDE_SESSIONS_LOADING:
      return {
        ...state,
        sessionsLoading: false,
      }
    case SHOW_SERVICES_LOADING:
      return {
        ...state,
        servicesLoading: true,
      }
    case HIDE_SERVICES_LOADING:
      return {
        ...state,
        servicesLoading: false,
      }
    case EXPORT_DATA:
      return {
        ...state,
        exportData: action.isFailed
          ? { success: false, error: action.payload }
          : { success: true, error: '' },
      }
      case EXPORT_TRANSACTIONS:
      return {
        ...state,
        exportTransactions: action.isFailed
          ? { success: false, error: action.payload }
          : { success: true, error: '' },
      }
    case SHOW_TRANSACTION_LOADING:
      return {
        ...state,
        transactionLoading: true,
      }
    case HIDE_TRANSACTION_LOADING:
      return {
        ...state,
        transactionLoading: false,
      }
    case SHOW_FULL_IMAGE__LOADING:
      return {
        ...state,
        fullImageLoading: true,
      }
    case SHOW_BOXED_IMAGE_LOADING:
      return {
        ...state,
        boxedImageLoading: true,
      }
    case HIDE_FULL_IMAGE__LOADING:
      return {
        ...state,
        fullImageLoading: false,
      }
    case HIDE_BOXED_IMAGE_LOADING:
      return {
        ...state,
        boxedImageLoading: false,
      }
    case GET_ORGANIZTION_BUNLDES:
      return {
        ...state,
        organiztionBundles: action.isFailed ? { error: action.payload } : action.payload,
      }
    case GET_BUNDLE_SERVICES:
      return {
        ...state,
        bundleServices: action.isFailed ? { error: action.payload } : action.payload,
      }
    case EVENT_SUCCEDED:
      return {
        ...state,
        eventHappened: !state.eventHappened,
      }
    case GET_BUNDLE_SESSIONS:
      return {
        ...state,
        bundleSessions: action.isFailed ? { error: action.payload } : action.payload,
        totalSessions: action.totalSessions,
      }
    case GET_BUNDLE_TRANSACTIONS:
      return {
        ...state,
        bundleTransactions: action.isFailed ? { error: action.payload } : action.payload,
        totalTransactions: action.totalTransactions,
      }
    case GET_BUNLDE_DETAILS:
      return {
        ...state,
        bundleDetails: action.isFailed ? { error: action.payload } : action.payload,
      }
    case GET_BUNDLE_ANALYTICS:
      return {
        ...state,
        bundleAnalytics: action.isFailed ? { error: action.payload } : action.payload,
      }
    case GET_SESSION_DETAILS:
      return {
        ...state,
        sessionDetails: action.isFailed ? { error: action.payload } : action.payload,
      }
    case GET_TRANSACTION_DETAILS:
      return {
        ...state,
        transactionDetails: action.isFailed ? { error: action.payload } : action.payload,
      }
    case GET_VALIFY_SERVICES:
      return {
        ...state,
        valifyServices: action.isFailed ? { error: action.payload } : action.payload,
      }
    case CREATE_ORGANIZATON:
      action.isFailed
        ? message.error(action.payload)
        : message.success('Organiztion Created Successfully!')
      return {
        ...state,
        createdOrganizationId: action.isFailed ? { error: action.payload } : action.payload.id,
      }
    case CREATE_BUNDLE:
      action.isFailed
        ? message.error(action.payload)
        : message.success('Bundle Created Successfully!')
      return {
        ...state,
        createdBundleId: action.isFailed ? { error: action.payload } : action.payload.bundle_id,
      }

    case GET_FULL_IMAGE:
      return {
        ...state,
        fullImage: action.isFailed ? { error: action.payload } : action.payload,
      }
    case GET_BOXED_IMAGE:
      return {
        ...state,
        boxedImage: action.isFailed ? { error: action.payload } : action.payload,
      }
    default:
      return state
  }
}

export default AdminData
