import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import { GET_BUNDLE_TRANSACTIONS_SAGA, GET_TRANSACTION_DETAILS_SAGA } from '../constants/AdminData'
import {
  clearAdminData,
  showSessionsLoading,
  hideSessionsLoading,
  getBundleTransactions,
  showTransactionLaoding,
  hideTransactionLaoding,
  getTransactionDetails,
  getTransactionDetailsSaga
} from '../actions/AdminData'
import { signOutSuccessAdmin } from 'redux/actions/Auth'
import AdminServices from 'services/AdminServices'
import AuthService from 'services/AuthService'

export function * getBundleTransactionsSagaGen () {
  yield takeEvery(GET_BUNDLE_TRANSACTIONS_SAGA, function * ({ payload }) {
    yield put(showSessionsLoading())
    try {
      const { data } = yield call(AdminServices.getBundleTransactions, payload)
      yield put(getBundleTransactions(data.transactions, false, data.total))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(getBundleTransactions(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(getBundleTransactions(e.response.data.message, true))
        } else {
          getBundleTransactions('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getBundleTransactions('Please make sure of your internet connection!', true))
      }
    }
    yield put(hideSessionsLoading())
  })
}

export function * getTransactionDetailsSagaGen () {
  yield takeEvery(GET_TRANSACTION_DETAILS_SAGA, function * ({ payload }) {
    yield put(showTransactionLaoding())

    try {
      const { data } = yield call(AdminServices.getTransactionDetails, {
        bundle_id: payload.bundleId,
        transaction_id: payload.transactionId
      })
      yield put(getTransactionDetails(data, false, data.total))
    } catch (e) {
      if (e.response) {
        if (e.response.status === 403) {
          try {
            yield call(AuthService.adminRefreshToken)
            yield put(getTransactionDetailsSaga(payload))
          } catch (e) {
            yield call(AuthService.logoutAdmin)
            localStorage.removeItem('USERNAME')
            localStorage.removeItem('isAdmin')
            yield put(signOutSuccessAdmin())
            yield put(clearAdminData())
          }
        } else if (e.response.data) {
          yield put(getTransactionDetails(e.response.data.message, true))
        } else {
          getTransactionDetails('Sorry ! Something went wrong . Please try again later !', true)
        }
      } else {
        yield put(getTransactionDetails('Please make sure of your internet connection!', true))
      }
    }
    yield put(hideTransactionLaoding())
  })
}

export default function * rootSaga () {
  yield all([fork(getBundleTransactionsSagaGen), fork(getTransactionDetailsSagaGen)])
}
