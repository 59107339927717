import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import AppLayout from 'layouts/app-layout'
import AuthLayout from 'layouts/auth-layout'
import AdminLayout from 'layouts/admin-layout'
import AppLocale from 'lang'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'
import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  ADMIN_PREFIX_PATH,
  SIGNUP_PREFIX_PATH,
} from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { signOut, signOutAdmin } from 'redux/actions/Auth'

function RouteInterceptor({ children, isAuthenticated, isInitialized, path, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          isInitialized ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: `${SIGNUP_PREFIX_PATH}/user-details`,
                state: { from: location },
              }}
            />
          )
        ) : (
          <>
            {path === APP_PREFIX_PATH ? (
              <Redirect
                to={{
                  pathname: AUTH_PREFIX_PATH,
                  state: { from: location },
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: `${AUTH_PREFIX_PATH}/login/admin`,
                  state: { from: location },
                }}
              />
            )}
          </>
        )
      }
    />
  )
}

export const Views = (props) => {
  const { locale, authenticated, isInitialized, location, direction, isAdmin } = props
  const currentAppLocale = AppLocale[locale]
  
  // The part below is to manage localStorage version and clear it in case of breaking changes
  const history = useHistory()
  const dispatch = useDispatch()
  const currentStorageVersion = '2'
  const [isStorageInvalid, setStorageInvalid] = useState(localStorage.getItem('storageVersion') !== currentStorageVersion)
  const [isStorageEmpty, setIsStorageEmpty] = useState(localStorage.length === 0)

  const clearStorage = () => {
     // Clear Local Storage
    localStorage.clear();
    
    // Clear Session Storage
    sessionStorage.clear();
    if('caches' in window){
      caches.keys().then((names) => {
      // Delete all the cache files
      names.forEach(name => {
      caches.delete(name);
        })
    });
    localStorage.setItem('storageVersion', currentStorageVersion)
    setStorageInvalid(false)
    // Makes sure the page reloads. Changes are only visible after you refresh.
    window.location.reload(true);
    }
    
    console.log('All caches and storage have been cleared.');
    localStorage.setItem('storageVersion', currentStorageVersion)
    setStorageInvalid(false)
    if (isAdmin) {
      dispatch(signOutAdmin())
      setTimeout(() => {
        history.replace(`${AUTH_PREFIX_PATH}/login/admin`)
      }, 500)
    } else {
      dispatch(signOut())
      setTimeout(() => {
        history.replace(`${AUTH_PREFIX_PATH}/login`)
      }, 500)
    }
  }

  if(isStorageEmpty){
    localStorage.setItem('storageVersion', currentStorageVersion)
    setIsStorageEmpty(false)
  }

  if (!isStorageEmpty && isStorageInvalid){
    clearStorage();
  }

  useBodyClass(`dir-${direction}`)
  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <RouteInterceptor
            path={APP_PREFIX_PATH}
            isAuthenticated={authenticated}
            isInitialized={isInitialized}
          >
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>

          <RouteInterceptor
            path={ADMIN_PREFIX_PATH}
            isAuthenticated={authenticated}
            isInitialized={isInitialized}
          >
            <AdminLayout direction={direction} location={location} />
          </RouteInterceptor>

          {/* Show 404 Page  */}
          {/* <Route>
            <Redirect to={`${AUTH_PREFIX_PATH}/error-1`} />
          </Route> */}

          {/* Redirect to login or dashboard */}
          <Route path="*">
            <Redirect to={AUTH_PREFIX_PATH} />
          </Route>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme
  const { authenticated, isInitialized, isAdmin } = auth
  return { locale, direction, authenticated, isInitialized, isAdmin }
}

export default withRouter(connect(mapStateToProps)(Views))
